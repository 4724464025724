import { COMPARISON_ID, CONTENT_TYPE } from '~/types/course.types';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { course, getActivity, setCourseId, isKickoffCompleted, getActivityUrl } = useCourseState();

  if (!course.value && to.params.courseId) {
    await setCourseId(to.params.courseId as string);
  }

  if (!course.value) {
    return;
  }

  // the rules below only apply to kickoff and mastery courses / workshops
  if (!course.value.kickoffAndMasteryQuizzesEnabled) {
    return;
  }

  const activityId = (() => {
    if (to.params.quizId) {
      return to.params.quizId as string;
    } else if (to.params.lessonId) {
      return to.params.lessonId as string;
    } else if (to.name === CONTENT_TYPE.COMPARISON) {
      return COMPARISON_ID.toString();
    } else {
      return null;
    }
  })();

  if (!activityId) {
    return;
  }

  const targetActivity = getActivity(activityId);

  if (!targetActivity) {
    return;
  }

  if (targetActivity.type === CONTENT_TYPE.KICKOFF_QUIZ) {
    return;
  }

  // if the kickoff quiz is not completed, we should not allow access to any other activity
  if (!isKickoffCompleted.value) {
    const activity = course.value.chapters.kickoff
      .flatMap(chapter => chapter.contents)
      .find(activity => {
        return activity.type === CONTENT_TYPE.KICKOFF_QUIZ;
      });

    if (!activity) {
      console.error('Couldn\'t find kickoff quiz. This course is broken', course.value.id);
      return;
    }

    return navigateTo(getActivityUrl(activity));
  }

  // if the target activity is a mastery quiz, we should redirect to the comparison page if the quiz is completed
  if (targetActivity.type === CONTENT_TYPE.MASTERY_QUIZ) {
    if (targetActivity.progress?.finished === true) {
      const activity = getActivity(COMPARISON_ID.toString());

      if (!activity) {
        console.error('Couldn\'t find comparison activity. This course is broken', course.value.id);
        return;
      }

      return navigateTo(getActivityUrl(activity));
    }

    if (targetActivity.locked && isKickoffCompleted.value) {
      const quizzes = course.value.chapters.regular
        .flatMap(chapter => chapter.contents)
        .filter(activity => activity.type === CONTENT_TYPE.QUIZ && (activity.progress?.passed === false || !activity.progress));

      if (quizzes.length === 0) {
        console.error('Something is wrong, there should be at least one regular quiz left', course.value.id);
        return;
      }

      return navigateTo(getActivityUrl(quizzes[0]));
    }
  }

});